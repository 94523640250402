.root {
  display: grid;
  align-items: center;
  grid-template-columns: 102px 236px 146px 82px 122px 80px 200px 24px;
  gap: 20px;
  padding: 13px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #F2F2F2;

  &.failed {
    background: #feeded;
  }

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
}

.cuttedText {
  @mixin cuttedText;
}

.amountWrap {
  display: flex;
  gap: 4px;
  align-items: center;
}