@value headerHeight from '~Styles/vars/size.css';

.root {
  display: grid;
  grid-template-columns: 102px 236px 146px 82px 122px 80px 200px 24px;
  gap: 20px;
  width: 100%;
  padding: 13px 20px;
  box-sizing: border-box;
  border-top: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;
  position: sticky;
  background: white;
  top: headerHeight;
  z-index: 1;
}